import React from 'react'
import PropTypes from 'prop-types'

function ArrowLeft({ fill }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 24 24">
      <defs/>
      <path fill={fill} d="M2.117 12l7.527 6.235L9 19l-9-7.521L9 4l.645.764L2.116 11H24v1H2.117z"/>
    </svg>
  )
}

ArrowLeft.propTypes = {
  fill: PropTypes.string,
}

ArrowLeft.defaultProps = {
  className: undefined,
  fill: '#000000',
}

export default ArrowLeft