import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import { isMobilePlatform } from '../utils/devices'
import { SubheadingCSS, Heading2CSS } from '../styles/typography'
import { breakpoints } from '../styles/breakpoints'

import Layout from '../components/layout/layout'
import SEO from '../components/seo/seo'
import Wrapper from '../components/wrapper/wrapper'
import ArrowLeft from '../components/icons/arrow-left'
import ArrowRight from '../components/icons/arrow-right'

const Details = styled.div`
  margin-bottom: 48px;

  @media ${breakpoints.laptop} {
    margin-bottom: 96px;
  }
`

const DetailsWrapper = styled.div`
  margin: auto;
  max-width: 600px;
  text-align: center;
`

const H2 = styled.h2`
  ${Heading2CSS}
  margin-bottom: 24px;

  @media ${breakpoints.laptop} {
    margin-bottom: 48px;
  }
`

const Gallery = styled.div`
  text-align: center;
`

const ImageContainer = styled.div`
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  @media ${breakpoints.laptop} {
    margin-bottom: 48px;
  }
`

const Image = styled.img`
  height: auto;
  max-width: 100%;
  vertical-align: center;
  width: auto;

  @media ${breakpoints.desktop} {
    max-width: ${props => props.isLandscape ? '1000px' : '600px'};
  }

  @media ${breakpoints.wide} {
    max-width: ${props => props.isLandscape ? '1200px' : '800px'};
  }
`

const Video = styled.video`
  height: auto;
  max-width: 100%;
  vertical-align: center;
  width: auto;

  @media ${breakpoints.desktop} {
    max-width: 600px;
  }

  @media ${breakpoints.wide} {
    max-width: 800px;
  }
`

const Pagination = styled.div`
  margin-top: 48px;

  @media ${breakpoints.laptop} {
    margin-top: 96px;
  }

  @media ${breakpoints.wide} {
    margin-top: 120px;
  }
`

const PaginationWrapper = styled.div`
  @media ${breakpoints.laptop} {
    display: flex;
    justify-content: center;
  }
`

const PaginationLink = styled(Link)`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 36px;
  text-decoration: none;

  &:hover,
  &:focus {
    color: grey;

    svg path {
      fill: grey;
    }
  }

  &.disabled {
    color: #aaaaaa;
    pointer-events: none;

    svg path {
      fill: #aaaaaa;
    }
  }

  &:last-child {
    margin-bottom: 0;
    text-align: right;
  }

  @media ${breakpoints.laptop} {
    justify-content: initial;
    margin: 0 16px;
  }

  @media ${breakpoints.wide} {
    margin: 0 48px;
  }

  svg {
    height: 12px;
    width: 12px;

    @media ${breakpoints.laptop} {
      height: 16px;
      width: 16px;
    }
  }
`

const PaginationLabel = styled.span`
  ${SubheadingCSS}
  margin: 0 8px;

  @media ${breakpoints.laptop} {
    min-width: 170px;
  }

  @media ${breakpoints.wide} {
    min-width: 240px;
  }
`

export default function Story(props) {
  const story = props.pageContext

  return (
    <Layout>
      <SEO title={story.title} description={story.title} />

      <Wrapper>
        <Details>
          <DetailsWrapper>
            <H2>{story.title}</H2>
          </DetailsWrapper>
        </Details>

        <Gallery>
          {story.images && story.images.map((image, i) => {
            const imageFile = image.file
            const contentType = imageFile.contentType

            if (contentType.includes('image')) {
              const details = imageFile.details.image
              const isLandscape = details.width >= details.height
              return (
                <ImageContainer key={i}>
                  <Image
                    src={`${imageFile.url}?w=${details.width >= 1200 ? '1200' : details.width}`}
                    isLandscape={isLandscape}
                    alt={imageFile.fileName} />
                </ImageContainer>
              )
            }

            if (contentType.includes('video')) {
              return (
                <ImageContainer key={i}>
                  <Video muted autoPlay loop playsInline>
                    <source src={imageFile.url} type="video/mp4" />
                    Sorry, your browser doesn't support embedded videos.
                  </Video>
                </ImageContainer>
              )
            }

            return null
          })}
        </Gallery>

        <Pagination>
          <PaginationWrapper>
            <PaginationLink
              to={story.previous ? `/portfolio/${story.previous.slug}` : '/'}
              className={!story.previous ? 'disabled' : ''}>
              <ArrowLeft />
              <PaginationLabel>Previous Story</PaginationLabel>
            </PaginationLink>
            <PaginationLink
              to={story.next ? `/portfolio/${story.next.slug}` : '/'}
              className={!story.next ? 'disabled' : ''}>
              <PaginationLabel>Next Story</PaginationLabel>
              <ArrowRight />
            </PaginationLink>
          </PaginationWrapper>
        </Pagination>
      </Wrapper>
    </Layout>
  )
}
