import React from 'react'
import PropTypes from 'prop-types'

function ArrowRight({ fill }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 24 24">
      <defs/>
      <path fill={fill} d="M21.883 12l-7.527 6.235L15 19l9-7.521L15 4l-.645.764L21.884 11H0v1h21.883z"/>
    </svg>
  )
}

ArrowRight.propTypes = {
  fill: PropTypes.string,
}

ArrowRight.defaultProps = {
  className: undefined,
  fill: '#000000',
}

export default ArrowRight